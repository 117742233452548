@import '~@anwalt.de/design-system/scss/definitions';

#anw-back-to-top {
  align-items: center;
  background: $primary;
  color: $white;
  display: flex;
  justify-content: center;
  z-index: $zindex-sticky;

  i {
    font-size: 26px;
  }
}
